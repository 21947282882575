import React from 'react'

interface Props extends React.SVGAttributes<SVGSVGElement> { }

const COLOUR_MOLTEN_CORE = '#ff5800'

function DealsTravelLogo(props: Props) {
  const { fill, ...svgProps } = props

  return (
    <svg {...svgProps} viewBox="0 0 120 32">
      <defs>
        <linearGradient id="a" x1="15.4" y1="37.9" x2="15.4" y2="18.5" gradientTransform="matrix(1 0 0 -1 0 53.9)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ff6f00"/>
          <stop offset="1" stopColor="#ff4100"/>
        </linearGradient>
        <linearGradient id="b" x1="37.9" y1="37.9" x2="37.9" y2="18.5" href="#a"/>
        <linearGradient id="c" x1="62.1" y1="37.9" x2="62.1" y2="18.5" href="#a"/>
        <linearGradient id="d" x1="84.9" y1="37.9" x2="84.9" y2="18.5" href="#a"/>
        <linearGradient id="e" x1="104.9" y1="37.9" x2="104.9" y2="17.8" href="#a"/>
      </defs>
      <path d="M96.7 30.5v1.2H64.9l-.9-3.2h-3.8l-.9 3.2h-34v-3.5a17.6 17.6 0 01-11 3.5H0V.4h14.3a17.6 17.6 0 0111 3.4V.4h25.3v12l4.5-12h14.1l4 11V.4h14.5v17.8h6.1a9.6 9.6 0 01-2.4-6.8C91.4 4.8 97 0 105 0a19.3 19.3 0 0112.5 4.2l2.6 2.1-4.5 6a9.2 9.2 0 013.8 7.7c0 7.5-5.7 12-14 12a21.5 21.5 0 01-8.6-1.5z" fill="#fff" />
      <path d="M14.2 3.9H3.5V28h10.7c7.6 0 13.1-4.4 13.1-12.2s-5.6-12-13.1-12zm0 17.8h-3.5V10.2h3.5c3.9 0 5.7 2.4 5.7 5.7a5.5 5.5 0 01-5.7 5.8z" fill={COLOUR_MOLTEN_CORE}/>
      <path fill={COLOUR_MOLTEN_CORE} d="M28.8 28H47v-6H36v-3.1h10.6v-6.1H36.1V10H47V3.8H28.8v24.3z"/>
      <path d="M57.5 3.9L48.8 28h7.9l.9-3.2h9.1l1 3.2h7.8L66.6 3.8zm1.9 15l2.7-8.6 2.7 8.6z" fill={COLOUR_MOLTEN_CORE}/>
      <path fill={COLOUR_MOLTEN_CORE} d="M84 3.9h-7.3V28H93v-6.4h-9V3.9z"/>
      <path d="M105.8 12.6c-2.6-.4-3.5-.8-3.5-1.7 0-.6.4-1.2 2.1-1.2a11.6 11.6 0 016.7 2.4l3.9-5.2a15.7 15.7 0 00-10.1-3.4c-6.6 0-10 3.7-10 7.9 0 6 6 7 10 7.7 2.4.4 3.4 1 3.4 1.9s-1.2 1.4-2.6 1.4a11 11 0 01-7.7-3.2l-3.8 5.5c2.6 2.3 6 3.9 11.1 3.9 6.5 0 10.3-3.1 10.3-8.5 0-5.6-5.9-6.8-9.8-7.5z" fill={COLOUR_MOLTEN_CORE}/>
    </svg>
  )
}

export default DealsTravelLogo
